$(document).foundation();

$(document).ready(function() {
  // Scroll button
  $(".slideshow-scrolldown").click(function() {
      $('html, body').animate({scrollTop: $('#home-content-1').offset().top -117 }, 'slow');
  }); 

});


  // Owl Carousels

  $(".main-slider").owlCarousel({
    navigation : false,
    loop: $('.main-slider').children().length > 1,
    items: 1,
    autoplay: true
  });

  AOS.init();

  var items = $('.thumbs li');
  console.log(items.length);

  if(items.length > 1) {

    $('.thumbs').owlCarousel({
      navigation: false,
      dots: true,
      loop: true,
      items: 1,
      autoplay: true
    });

  } else {

    $(".thumbs").owlCarousel({
      navigation: false,
      dots: false,
      loop: false,
      items: 1,
      autoplay: true
    });

  }


  $(".testimonial-slider ul").owlCarousel({
    navigation : false,
    dots: false,
    loop : true,
    items: 1,
    autoplay: true
  });



// Gallery

var $sync1 = $(".gallery-main"),
	$sync2 = $(".gallery-thumbs"),
	flag = false,
	duration = 300;

		$sync1
			.owlCarousel({
				items: 1,
        nav: true,
				dots: false,
        singleItem:true,
        autoHeight: true,
        navText: ["&lsaquo;","&rsaquo;"]
			})
			.on('changed.owl.carousel', function (e) {
				if (!flag) {
					flag = true;
					$sync2.trigger('to.owl.carousel', [e.item.index, duration, true]);
					flag = false;
				}
			});

		$sync2
			.owlCarousel({
				margin: 20,
				items: 10,
				nav: false,
				center: true,
				dots: true,
			})
			.on('click', '.owl-item', function () {
				$sync1.trigger('to.owl.carousel', [$(this).index(), duration, true]);
			})
			.on('changed.owl.carousel', function (e) {
				if (!flag) {
					flag = true;
					$sync1.trigger('to.owl.carousel', [e.item.index, duration, true]);
					flag = false;
				}
			});





  // Back to top button

  $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
          $('.scrollup').fadeIn();
      } else {
          $('.scrollup').fadeOut();
      }
  });

  $('.scrollup').click(function () {
      $("html, body").animate({
          scrollTop: 0
      }, 600);
      return false;
  });


$(window).load(function() {

});
